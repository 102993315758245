import React, { useState, useEffect } from 'react';
import {isMobile, isBrowser} from 'react-device-detect';
import './App.css';
// import BgImage from '../public/assets/background.jpg';
export const SOCIAL = {
  LINK_FB: 'https://www.facebook.com/bonwinuytin/',
  LINK_TELE: 'https://t.me/bonwin9999',
  LINK_DOWN_APK: 'https://thitchungmamtep.pro/BonWin.apk',
  LINK_DOWN_IOS: 'https://apps.apple.com/us/app/crazy-fruit-crusher/id6670736613',
  LINK_PLAY: 'https://bonwin.club',
  LINK_DNS: 'https://t.me/YenNhi2006',
};

function App() {
  let onlyIos = false;
    if (navigator.platform === 'iPad' || navigator.platform === 'iPhone' || navigator.platform === 'iPod') {
       onlyIos = true;
    }
  if(isBrowser) {
    return (
      <div className="h-[100vh] min-w-[770px] w-full flex justify-center flex-col items-center justify-around background">
        <div style={{maxWidth: '400px', height: 'fit-content', marginTop: '-5px'}} className='nav-bar'>
        <a target="_blank" href={SOCIAL.LINK_FB}>
        <img  style={{width: '55px'}} className="Facebook h-full" src="/assets/btn-fb.png" />
        </a>
        <img className='' style={{width: '115px'}} src='/assets/logo.png' />
        <a target="_blank" href={SOCIAL.LINK_TELE}>
        <img  style={{width: '55px'}} className="Trele h-full" src="/assets/btn-tele.png" />
        </a>
      </div>
      <img style={{maxHeight: '120px', width: 'auto', marginTop: '-30px'}}  className="title" src="/assets/title.png" />
      <img style={{height: '65%', maxWidth: '120%', marginTop: '-120px'}} className="banner" src="/assets/banner.png" />

      <img style={{maxWidth: '350px', marginTop: '-70px', marginBottom: '10px'}} className="sub-title" src="/assets/sub-title.png" />
        <div className="flex flex-col w-[50%] items-center" style={{gap: '5px'}}>

      <a target="_blank" style={{maxWidth: '200px'}} href={SOCIAL.LINK_DOWN_IOS} >

<img className="Group12 " src="/assets/btn-download2.png" />
</a>
<a target="_blank" style={{maxWidth: '200px'}} href={SOCIAL.LINK_DOWN_APK} >
        
        <img className="Grou3p" src="/assets/btn-download.png" />
        </a>
      <a target="_blank" style={{maxWidth: '200px'}} href={SOCIAL.LINK_PLAY} >

<img className="BvdTach0017Group231" src="/assets/btn-play.png" />

</a>
<a target="_blank" style={{maxWidth: '200px', width: '100%', marginTop: '-15px'}} href={SOCIAL.LINK_DNS} className="dns">
      <img className="BtnDns1 " src="/assets/btn-dns.png" />
      </a>
        </div>
        
      </div>
    )
  }
  
  return (
    <div className="Frame1 p-[10px] w-full flex justify-center flex-col items-center background" >
     
      <div className='nav-bar'>
        <a target="_blank" href={SOCIAL.LINK_FB}>
        <img className="Facebook h-full" src="/assets/btn-fb.png" />
        </a>
        <img className='logo' src='/assets/logo.png' />
        <a target="_blank" href={SOCIAL.LINK_TELE}>
        <img className="Trele h-full" src="/assets/btn-tele.png" />
        </a>
      </div>
      <img className="title" src="/assets/title.png" />
      <img className="banner" src="/assets/banner.png" />

      <img className="sub-title" src="/assets/sub-title.png" />

      <div className='wrapper'>
       {onlyIos ? (
        <a target="_blank" href={SOCIAL.LINK_DOWN_IOS} className=" mt-[100px]">

        <img className="Group12 " src="/assets/btn-download2.png" />
</a>
       ) : (
        <a target="_blank" href={SOCIAL.LINK_DOWN_APK} className=" mt-[100px]">
        
        <img className="Grou3p" src="/assets/btn-download.png" />
        </a>)}
       
        
        <a target="_blank" href={SOCIAL.LINK_PLAY} className=" mt-[10px]">

      <img className="BvdTach0017Group231" src="/assets/btn-play.png" />

      </a>

      </div>
        <a target="_blank" href={SOCIAL.LINK_DNS} className="dns">
      <img className="BtnDns1 " src="/assets/btn-dns.png" />
      </a>
    </div>
  );
}

export default App;
